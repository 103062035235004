.container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    font-family: var(--main-font);
    box-sizing: border-box;
    max-height: 100%;
}

.navContainer {
    position: fixed;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 50px;
    height: 30px;
    transition: all ease 0.7s;
}

.navContainer.active {
    height: 100%;
    max-height: 100%;
    z-index: 1;
    transition: all ease 0.7s;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    padding-top: 50px;
    box-sizing: border-box;
}

.menuIcon {
    background-color: white;
    padding: 5px;
    font-size: 2rem;
    color: var(--main-nav-color);
}

.navLinks {
    height: 0px;
    opacity: 0;
    pointer-events: none;
}

.navLinks.active {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: all ease 0.8s;
    pointer-events: all;
}

.navItem {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: var(--alt-text-color);
}

.navItem:hover,
.navItem:active {
    background-color: #6338ff36;
    color: var(--main-color)
}

.navItem a {
    color: inherit;
    text-decoration: none;
}

.navItem svg {
    margin-right: 5px;
}