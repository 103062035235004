.container {
    padding: 2%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    position: relative;
}

.floatingButton {
    color: var(--main-color);
    background-color: white;
    width: 50px;
    height: 50px;
    font-size: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(-20%, -20%);
    user-select: none;
}

.floatingButton:active {
    box-shadow: none;
    opacity: 0.9;
}