.container {
    padding: 2%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    max-height: 100%;
    overflow: hidden;
}

.container h3 {
    font-size: 1rem;
    padding: 0 2%;
}

.minilabel {
    font-weight: 500;
    font-size: 0.8rem;
    padding: 3px 2%;
}

.listContainer {
    padding: 1%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    max-height: 100%;
    overflow-y: scroll;
}

.listItem {
    display: flex;
    background-color: white;
    border-radius: 10px;
    max-width: 100%;
    position: relative;
}

.imageContainer {
    max-width: 25%;
    height: min-content;
    border-radius: 10px;
}

.imageContainer img {
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.description {
    max-width: 75%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 0.2rem;
    height: min-content;
}

.description p {

    padding: 2px;
    font-size: 0.8rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 45px;
}

.description p span {
    font-weight: bold;
}

.removeIcon {
    background-color: var(--main-color);
    width: 20px;
    height: 20px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-30%, 25%);
}

.removeIcon:hover {
    opacity: 0.8;
}