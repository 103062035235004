.container {
    background-color: white;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    max-height: 100%;
    overflow: hidden;
}

.container h3 {
    font-size: 0.9rem;
    padding: 0 2%;
}

.container [type="search"] {
    border: none;
    outline: none;
    background-color: #f4f4f4;
    padding: 5px;
    border-radius: 9px;
    font-family: var(--main-font);
    border: 2px solid #f4f4f4;
    margin-left: 2%;
    max-width: 300px;
}

.minilabel {
    font-weight: 500;
    font-size: 0.7rem;
    padding: 3px 2%;
}

.listContainer {
    background-color: #f4f4f4;
    padding: 1%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    max-height: 100%;
    overflow-y: scroll;
    position: relative;
}

.tableHeader {
    background-color: white;
    display: flex;
    padding: 5px;
    align-items: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 0;
}

.checkbox {
    margin: 5px;
}

.divider {
    color: #e4e4e4;
}

.label {
    width: 200px;
    font-size: 0.9rem;
    padding: 3px;
    overflow: hidden;
}

.removeIcon {
    background-color: var(--main-color);
    width: 20px;
    height: 20px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: auto;
    cursor: pointer;
}

.removeIcon:hover {
    opacity: 0.8;
}

.tableItem {
    background-color: white;
    display: flex;
    padding: 5px;
    align-items: center;
}

.container button {
    background-color: #007FFF;
    border: 1px solid #007FFF;
    width: 70px;
    border-radius: 10px;
    padding: 5px;
    font-family: var(--main-font);
    font-weight: bold;
    color: white;
    margin: 10px;
}

.container button:hover,
.container button:active {
    opacity: 0.7;
}