.container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding: 5px;
}


.imageContainer {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 3px;
    margin-right: 5px;
    width: 20%;
    display: flex;
    aspect-ratio: 1 / 1;
}

.desc {
    width: 75%;
    display: flex;
    flex-direction: column;

}

.imageContainer img {
    max-width: 100%;
    display: block;
    border-radius: 15px;
    object-fit: contain;
    overflow: hidden;
}

.container p {
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container p span {
    font-size: 13px;
    font-weight: bold;

}